
@import '~antd/dist/antd.less';

.hide {
  display: none !important;
}

.mb-15 {
  font-size: 24px !important; // Tamaño por defecto para escritorio

  @media (max-width: 768px) {
    font-size: 20px !important; // Para tabletas
  }

  @media (max-width: 576px) {
    font-size: 16px !important; // Para móviles
  }
}
@primary-color: #054159;@primary-color-hover:  color(~`colorPalette('@{primary-color}', 5) `);@border-radius-base: 6px;@icon-color-hover: fade(@primary-color, 75%);@link-color: #054159;@link-hover-color: #054159;